import React from "react";
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import ArticleLayoutComponent from "../layouts/ArticleLayoutComponent";

import BannerBlockComponent from "../blocks/BannerBlockComponent";
import SidebarBlockComponent from "../blocks/SidebarBlockComponent";
import TagsListBlockComponent from "../blocks/TagsListBlockComponent";

import normalizeUrlHelper from "../../helpers/normalizeUrlHelper";

const ArticleTemplateComponent = ({ pageContext }) => {
    const {t} = useTranslation();
    const article = pageContext.article.frontmatter;
    const categoryUrl = "/categories/" + normalizeUrlHelper(article.category);

    return(
        <ArticleLayoutComponent article={article}>
          <div className="container d-flex justify-content-center py-5 article-template-wrapper">
            <div className="row flex-wrap-reverse">
              <div className="col-12 col-md-9 col-xl-10 fw-light fs-2-integral mw-960px article-content">
                <p>{article.date} - <Link className="ps-1 text-primary" to={categoryUrl}>{article.category}</Link></p>
                { ReactHtmlParser(pageContext.article.html) }

                <TagsListBlockComponent tags={article.tags} />
              </div>

              <div className="col-12 col-md-3 col-xl-2 pt-md-3 d-flex">
                <SidebarBlockComponent />
              </div>
            </div>
          </div>

          <BannerBlockComponent catchPhrase={t("app.catchPhrase.haveYouProject")} />
        </ArticleLayoutComponent>
    );
};

export default ArticleTemplateComponent;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

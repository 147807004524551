import React from "react";
import { Link } from 'gatsby-plugin-react-i18next';

import TagIcon from "../../assets/medias/svg/icons/icon-tag.svg";

import normalizeUrlHelper from "../../helpers/normalizeUrlHelper";

const TagsListBlockComponent = ({ tags }) => {
    const TagsParser = () => {
        let tagsArray = [];

        tags.forEach((tag, index) =>  {
            let tagUrl = "/tags/" + normalizeUrlHelper(tag);

            tagsArray.push(
                <div key={index} className="me-3 tag-wrapper">
                    <TagIcon />
                    <Link className="ps-1 text-primary" to={tagUrl}>{tag}</Link>
                </div>
            );
        });

        return(
            <>{tagsArray}</>
        );
    };

    return(
        <div className="d-flex">
            <TagsParser />
        </div>
    );
};

export default TagsListBlockComponent;

import React, { useRef } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import ChevronDownIcon from "../../assets/medias/svg/icons/icon-chevron-down.svg";
import ChevronRightIcon from "../../assets/medias/svg/icons/icon-chevron-right.svg";

import normalizeUrlHelper from "../../helpers/normalizeUrlHelper";
import useConfigsQuery from "../../data-loader/ConfigsDataLoader";

const SidebarBlockComponent = () => {
    const {t} = useTranslation();
    const dropdownRef = useRef();
    const { allFile } = useConfigsQuery();

    const categoriesFileNode = allFile.nodes.find( (node) => node.name === "categories");
    const categoriesList = JSON.parse(categoriesFileNode.internal.content).categoriesList;

    const CategoriesListParser = ({ isMobile }) => {
        let categoriesArray = [];

        categoriesList.forEach((category, index) => {
            categoriesArray.push(
                <li key={index} className="d-flex align-items-center px-3 py-2 p-md-1">
                    {isMobile ? <></> : <ChevronRightIcon />}
                    <Link className="text-primary" to={"/categories/" + normalizeUrlHelper(category)}>
                        {category}
                    </Link>
                </li>
            );
        });

        return(
            <>
                <li className="d-flex align-items-center px-3 py-2 p-md-1">
                    {isMobile ? <></> : <ChevronRightIcon />}
                    <Link className="text-primary" to="/blog">
                        {t("app.blog.seeAllArticles")}
                    </Link>
                </li>

                {categoriesArray}
            </>
        );
    };

    const toggleDropdown = () => {
        dropdownRef.current.classList.toggle("d-block");
    };

    return(
        <>
            <div className="d-none d-md-block p-2 sidebar-wrapper sidebar-pc">
                <h4 className="fs-3 ls-1">{t("app.title.categories")}</h4>

                <ul className="d-flex flex-column">
                    <CategoriesListParser />
                </ul>
            </div>

            <div className="d-block d-md-none mb-3 sidebar-wrapper sidebar-mobile">
                <h4 className="fs-3 ls-1">{t("app.title.categories")}</h4>

                <div className="flex-shrink-2 fw-light p-2 dropdown-selector" onClick={toggleDropdown}>
                    <span className="me-2">{t("app.blog.seeCategory")}</span>
                    <ChevronDownIcon />
                </div>
                <ul ref={dropdownRef} className="dropdown-content">
                    <CategoriesListParser isMobile />
                </ul>
            </div>
        </>
    );
};

export default SidebarBlockComponent;

import { graphql, useStaticQuery } from "gatsby";

const useConfigsQuery = () => {
    // Get all files from "/data/configs"
    const { allFile } = useStaticQuery(graphql`
        query ConfigsQuery {
            allFile(filter: {sourceInstanceName: {eq: "data-configs"}}) {
                nodes {
                    name
                    internal {
                        content
                    }
                }
            }
        }
    `);

    return ({ allFile });
};

export default useConfigsQuery;

import React from "react";

import FooterComponent from "../FooterComponent";
import HeaderWrapperComponent from "../headers/HeaderWrapperComponent";
import SEOComponent from "../SEOComponent";

const PageLayoutComponent = ({ children, article }) => {
    return (
        <>
            <SEOComponent articleNode={article} articleSEO />

            <HeaderWrapperComponent type="small" title={article.title} subtitle={article.description} cover={article.cover} />

            <main>{ children }</main>

            <FooterComponent/>
        </>
    )
};

export default PageLayoutComponent;
